import React from 'react'
import _times from 'lodash/times'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import { TitleSection } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import Layout from '../components/Layout'

import image_background from '../images/blog_background.jpg'

const AboutUs = props => (
  <Layout
    title="Nosotros"
    description="En 1991 fundamos Arreguín Ruiz Sánchez Cabrera y Asociados S.A. de C.V (AS Consulting Group) con la finalidad de proveer soluciones contables y cumplir con las nuevas obligaciones fiscales"
    {...props}
  >
    <SectionHeaderImage image={image_background} position="0" />
    <Section>
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <TitleSection>
          <FormattedHTMLMessage id="aboutUs.title" />
        </TitleSection>
        <FormattedHTMLMessage id="aboutUs.description" />
      </div>
    </Section>

    {/* <Section id="view">
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <h3>
          <FormattedHTMLMessage id="aboutUs.view.title" />
        </h3>
        <div>
          <FormattedHTMLMessage id="aboutUs.view.p1" />
        </div>
      </div>
    </Section> */}

    <Section id="consulting">
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <h3>
          <FormattedHTMLMessage id="aboutUs.consulting.title" />
        </h3>
        <div>
          <FormattedHTMLMessage id="aboutUs.consulting.p1" />
        </div>
      </div>
    </Section>

    <Section id="culture_values">
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <h3 style={{ textTransform: 'uppercase' }}>
          <FormattedHTMLMessage id="aboutUs.culture_values.title" />
        </h3>
        <div>
          <FormattedHTMLMessage id="aboutUs.culture_values.p1" />
        </div>
      </div>
    </Section>

    <Section id="philosophy">
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <h3 style={{ textTransform: 'uppercase' }}>
          <FormattedHTMLMessage id="aboutUs.philosophy.title" />
        </h3>
        <h4 style={{ textTransform: 'uppercase' }}>
          <FormattedHTMLMessage id="aboutUs.philosophy.subtitle" />
        </h4>
        <ul>
          {_times(7, Number).map((index, key) => (
            <li key={key}>
              <FormattedHTMLMessage
                id={`aboutUs.philosophy.list.item${index + 1}`}
              />
            </li>
          ))}
        </ul>
      </div>
    </Section>

    <Section id="last_years">
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <h3 style={{ textTransform: 'uppercase' }}>
          <FormattedHTMLMessage id="aboutUs.last_years.title" />
        </h3>
        <ul>
          {_times(7, Number).map((index, key) => (
            <li key={key}>
              <FormattedHTMLMessage
                id={`aboutUs.last_years.list.item${index + 1}`}
              />
            </li>
          ))}
        </ul>
      </div>
    </Section>

    {/* <Section id="solid_structure">
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <h3>
          <FormattedHTMLMessage id="index.divider1_content_title8" />
        </h3>
        <div>
          <FormattedHTMLMessage id="aboutUs.solid_structure.content" />
          <ul>
            <li>
              <FormattedHTMLMessage id="aboutUs.solid_structure.list_content1" />
            </li>

            <li>
              <FormattedHTMLMessage id="aboutUs.solid_structure.list_content2" />
            </li>

            <li>
              <FormattedHTMLMessage id="aboutUs.solid_structure.list_content3" />
            </li>
          </ul>
        </div>
      </div>
    </Section>
    <Section id="tecnology">
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <h3>
          <FormattedHTMLMessage id="index.divider1_content_title7" />
        </h3>
        <div>
          <FormattedHTMLMessage id="aboutUs.tecnology.content" />
          <ul>
            <li>
              <FormattedHTMLMessage id="aboutUs.tecnology.list_content1" />
            </li>

            <li>
              <FormattedHTMLMessage id="aboutUs.tecnology.list_content2" />
            </li>

            <li>
              <FormattedHTMLMessage id="aboutUs.tecnology.list_content3" />
            </li>

            <li>
              <FormattedHTMLMessage id="aboutUs.tecnology.list_content4" />
            </li>
          </ul>
        </div>
      </div>
    </Section>
    <Section id="clients">
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <h3>
          <FormattedHTMLMessage id="index.divider1_content_title3" />
        </h3>
        <div>
          <FormattedHTMLMessage id="aboutUs.clients.content" />
        </div>
      </div>
    </Section>
    <Section id="clients">
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <h3>
          76 % <FormattedHTMLMessage id="index.divider1_content_title3" />
        </h3>
        <div>
          <FormattedMessage id="aboutUs.clients.content" />
        </div>
      </div>
    </Section>
    <Section id="languages">
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <h3>
          <FormattedHTMLMessage id="index.divider1_content_title6" />
        </h3>
        <div>
          <FormattedHTMLMessage id="aboutUs.languages.content" />
          <ul>
            <li>
              <FormattedHTMLMessage id="aboutUs.languages.list_content1" />
            </li>
            <li>
              <FormattedHTMLMessage id="aboutUs.languages.list_content2" />
            </li>
          </ul>
        </div>
      </div>
    </Section>
    <Section id="sms">
      <div
        style={{ textAlign: 'justify', maxWidth: '800px', margin: '0 auto' }}
      >
        <h3>
          <FormattedHTMLMessage id="index.divider1_content_title4" />
        </h3>
        <div>
          <FormattedHTMLMessage id="aboutUs.sms.content" />
        </div>
      </div>
    </Section> */}
  </Layout>
)

export default AboutUs
